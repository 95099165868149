<template>
  <TTView>
    <VRow>
      <VCol>
        <ChoicesTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import ChoicesTableContainer from '../../../components/multi-level-test/choices/ChoicesTableContainer.vue';

export default {
  name: 'QuestionsChoices',

  components: {
    ChoicesTableContainer,
  },
};
</script>
