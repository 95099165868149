<template>
  <ChoicesTable
    :choices="choices"
    :questions="questions"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{
          name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_CREATE
        }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый ответ
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить ответ
          </VCardTitle>

          <VCardText>
            Ответ <span class="red--text">{{ choice.title }}</span> будет удален! <br>
            Действительно хотите удалить ответ?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_VIEW,
              params : {
                testId : testId,
                levelId : levelId,
                questionId : questionId,
                choiceId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{
              name : Names.R_MULTI_LEVEL_TEST_TESTS_LEVELS_QUESTIONS_CHOICES_EDIT,
              params : {
                testId : testId,
                levelId : levelId,
                questionId : questionId,
                choiceId : item.id
              }
            }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteChoice(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </ChoicesTable>
</template>

<script>
import ChoicesTable from './ChoicesTable.vue';

export default {
  name: 'ChoicesTableContainer',

  components: {
    ChoicesTable,
  },

  inject: ['Names'],

  data() {
    return {
      choices: [],
      questions: [],
      loading: false,
      choice: {},
      confirmDialog: false,
    };
  },

  computed: {
    testId() {
      return this.$route.params.testId;
    },

    topicId() {
      return this.$route.params.topicId;
    },

    levelId() {
      return this.$route.params.levelId;
    },

    questionId() {
      return this.$route.params.questionId;
    },

    choiceId() {
      return this.$route.params.choiceId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { questionId } = this;
        const data = { questionId };

        const { choices } = await this.$di.api.MultiLevelTest.ChoicesByQuestion({ data });
        const { questions } = await this.$di.api.MultiLevelTest.QuestionsIndex();

        this.choices = choices;
        this.questions = questions;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleDelete() {
      try {
        this.loading = true;

        const { id } = this.choice;
        const data = { id };

        await this.$di.api.MultiLevelTest.ChoicesDelete({ data });

        this.resetChoiceEdit();
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
        await this.fetch();
      }
    },

    resetChoiceEdit() {
      this.confirmDialog = false;
      this.choice = {};
    },

    handleCancel() {
      this.resetChoiceEdit();
    },

    deleteChoice(item) {
      this.confirmDialog = true;
      this.choice = { ...item };
    },
  },
};
</script>
